import React from 'react';
import { Box } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { PostModel } from '../../Models';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { ForumContext } from '../../ForumReducer';
import _ from 'lodash';

type Props = {
    readonly post: PostModel;
    readonly level: number;
    readonly selectedPostId?: string;
    readonly isSubscribed?: boolean;
    readonly subscribe?: (event: React.MouseEvent<HTMLButtonElement> | undefined) => void
    readonly unsubscribe?: (event: React.MouseEvent<HTMLButtonElement> | undefined) => void
};

const PostSummary = ((props: Props) => {
    const [forum, _] = React.useContext(ForumContext);

    const user = forum.users[props.post.userId];

    const hasBody = props.post.body !== undefined && props.post.body !== null && props.post.body.length > 0;

    return (
        <Box
            sx={{
                marginLeft: '10px'
            }}>
            {props.post.isRemoved ?
                <strong><small style={{ color: 'red', verticalAlign: 'top' }}>(removed)</small></strong>
            :
                <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                >
                    <div style={{ overflowWrap: 'anywhere', paddingRight: '10px' }}>
                        {props.selectedPostId && props.selectedPostId === props.post.id ?
                            <strong style={{ color: 'red', textDecoration: hasBody ? 'underlined' : 'none' }}>{props.post.subject}</strong>
                            : <Link to={`/posts/${props.post.id}`} style={{ textDecoration: hasBody ? 'underlined' : 'none' }}><strong>{props.post.subject}</strong></Link>
                        }
                        <Box
                            sx={{
                                display: 'inline-flex',
                                margin: '0 4px',
                                '& div': {
                                    border: '1px solid black',
                                    fontSize: '8px',
                                    px: '2px'
                                }
                            }}>
                            {!hasBody &&
                                <div style={{ backgroundColor: 'white' }}>nt</div>
                            }
                            {props.post.hasUrl &&
                                <div style={{ backgroundColor: 'lightsalmon' }}>url</div>
                            }
                            {props.post.hasImages &&
                                <div style={{ backgroundColor: 'lightgreen' }}>pic</div>
                            }
                            {props.post.hasVideo &&
                                <div style={{ backgroundColor: 'lightskyblue' }}>video</div>
                            }
                        </Box>
                        <span style={{ whiteSpace: 'break-spaces' }}> by {user !== undefined ? user.name : props.post.userId}</span>
                        <span style={{ whiteSpace: 'break-spaces', margin: '0 4px' }}><small><Moment date={props.post.createdAt} fromNow /></small></span>
                    </div>
                    <Box>
                        {!props.isSubscribed && props.subscribe &&
                            <button onClick={props.subscribe} style={{ fontSize: 'x-small', cursor: 'pointer', backgroundColor: 'lightgrey' }}><strong>subscribe</strong></button>
                        }
                        {props.isSubscribed && props.unsubscribe &&
                            <button onClick={props.unsubscribe} style={{ fontSize: 'x-small', cursor: 'pointer', backgroundColor: 'cadetblue' }}><strong>unsubscribe</strong></button>
                        }
                    </Box>
                </Box>
            }
            <Box>
                {props.post.childPosts.map(childPost => {
                    return (
                        <PostSummary
                            post={childPost}
                            level={props.level + 1}
                            selectedPostId={props.selectedPostId} />
                    );
                })}
            </Box>
        </Box>
    );
});

export default PostSummary;