import React from 'react';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { Box, Button, Container, FormGroup, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserReducer';
import { User } from '../Models';

const Login = () => {
    const [_, userDispatch] = React.useContext(UserContext);

    const navigate = useNavigate();
    const [values, setValues] = React.useState({
        name: '',
        password: ''
    });

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [name]: event.target.value });
    };

    const login = (_: React.MouseEvent<HTMLButtonElement> | undefined) => {
        Axios
            .post('/api/login', {
                name: values.name,
                password: values.password
            })
            .then((response: AxiosResponse<User>) => {
                userDispatch({
                    type: 'setUser',
                    user: response.data
                });
                navigate('/f');
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    };

    return (
        <Box
            sx={{
                '& .MuiTextField-root': { m: 1 }
            }}
            component="form"
            noValidate
        >
            <Container>
                <form>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <img src="favicon.ico" />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <FormGroup sx={{ width: '300px;' }}>
                            <TextField label="Username" autoComplete='username' onChange={handleChange('name')} variant="outlined" />
                            <TextField label="Password" type='password' autoComplete='current-password' onChange={handleChange('password')} variant="outlined" />
                        </FormGroup>
                    </Box>
                    <Box
                        sx={{ my: 2 }}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-around"
                        alignItems="center"
                        minHeight="6em">
                        <Button variant="contained" color="success" onClick={login}>Login</Button>
                        <Button variant="text" size="small" color="secondary" onClick={() => navigate('/newuser')} >New User</Button>
                    </Box>
                </form>
            </Container>
        </Box>
    );
}

export default Login;
