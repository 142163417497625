import React from 'react';
import { Box, TextField } from '@mui/material';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { User } from '../Models';

const Messages = () => {
    
    React.useEffect(() => {

    }, []);

    return (
        <Box mx='1em' paddingTop='1em'>
            <h3>Messages</h3>
            <h5>coming soon</h5>
        </Box>
    );
};

export default Messages;