import _ from "lodash";
import React from "react";
import Moment from "react-moment";
import { Subscription } from "./Models";

export type SubscriptionsAction = {
    readonly type: 'setSubscriptions',
    readonly subscriptions: Subscription[];
} | {
    readonly type: 'addSubscription',
    readonly subscription: Subscription;
} | {
    readonly type: 'removeSubscription',
    readonly threadId: string;
} | {
    readonly type: 'updateSubscription',
    readonly threadId: string;
};

export const SubscriptionsReducer = (state: Subscription[], action: SubscriptionsAction): Subscription[] => {
    if (action.type === 'setSubscriptions') {
        return action.subscriptions;
    }

    if (action.type === 'addSubscription') {
        return _.union(state, [action.subscription]);
    }

    if (action.type === 'removeSubscription') {
        return state.filter(sub => sub.threadId !== action.threadId);
    }

    if (action.type === 'updateSubscription') {
        return state.filter(sub => sub.threadId !== action.threadId).concat({
            threadId: action.threadId,
            lastReadAt: new Date()
        });
    }

    return state;
};

export const SubscriptionsContext = React.createContext<[state: Subscription[], userDispatch: React.Dispatch<SubscriptionsAction>]>([[], new Object as React.Dispatch<SubscriptionsAction>]);
