import React from 'react';
import { Box, Button, FormGroup } from '@mui/material';
import { PostModel, SavePostModel } from '../../Models';

type Props = {
    readonly editPost?: PostModel;
    savePost(post: SavePostModel): void;
};

const PostForm = (props: Props) => {
    const [post, setPost] = React.useState<SavePostModel>({
        subject: props.editPost ? props.editPost.subject : '',
        body: props.editPost && props.editPost.body ? props.editPost.body : '',
    });

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setPost({ ...post, [name]: event.target.value });

    const save = (_: React.MouseEvent<HTMLButtonElement> | undefined) =>
        props.savePost(post);
        
    return (
        <Box
            sx={{
                border: '1px solid black',
                my: '4px',
                p: '4px',
                backgroundColor: 'darkgrey'
            }}>
            <div>
                <FormGroup
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '10px',
                        width: '100%',
                        maxWidth: '600px'
                    }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '4px'
                    }}>
                        <input placeholder='Subject' type='text' style={{ backgroundColor: 'lightgray' }} defaultValue={post.subject} onChange={handleChange('subject')} />
                        <textarea placeholder="nt" style={{ minHeight: '150px', backgroundColor: 'lightgray' }} defaultValue={post.body} onChange={handleChange('body')} />
                    </Box>
                    <div>
                        <Button variant="contained" size='small' color="primary" onClick={save}>Reply</Button>
                    </div>
                </FormGroup>
            </div>
        </Box>
    );
};

export default PostForm;