import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './Index.css';
import App from './Components/App';
import Login from './Components/Login';
import Forum from './Components/Forum/Forum';
import NewUser from './Components/NewUser';
import ManageUser from './Components/ManageUser';
import reportWebVitals from './reportWebVitals';
import Search from './Components/Forum/Search';
import Post from './Components/Forum/Post';
import Messages from './Components/Messages';
import Media from './Components/Media';
import EditPost from './Components/Forum/EditPost';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: 'posts/:postId',
        element: <Post />
      },
      {
        path: 'posts/:postId/edit',
        element: <EditPost />
      },
      {
        path: "f",
        element: <Forum />,
      },
      {
        path: "f/:pageNumber",
        element: <Forum />,
      },
      {
        path: "media",
        element: <Media />,
      },
      {
        path: "messages",
        element: <Messages />,
      },
      {
        path: "search",
        element: <Search />,
      },
      {
        path: 'user',
        element: <ManageUser />
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "newuser",
        element: <NewUser />,
      },
    ]
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
