import React from 'react';
import { Box, Button, Chip } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { SavePostModel, PostModel } from '../../Models';
import Moment from 'react-moment';
import { useNavigate, useParams } from 'react-router-dom';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import DOMPurify from 'dompurify';
import { ForumContext } from '../../ForumReducer';
import NewPostForm from './PostForm';
import { UserContext } from '../../UserReducer';

const EditPost = () => {
    const navigate = useNavigate();
    const { postId } = useParams();
    const [forum, _forumReducer] = React.useContext(ForumContext);
    const [user, _userReducer] = React.useContext(UserContext);
    const [post, setPost] = React.useState<PostModel | undefined>(undefined);

    React.useEffect(() => {
        Axios
            .get(`/api/posts/${postId}`)
            .then((response: AxiosResponse<PostModel>) => {
                setPost(response.data);
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }, [postId]);

    if (!post) {
        return (
            <Box
                sx={{
                    mx: '1em',
                    paddingTop: '1em'
                }}>
                <Box sx={{
                    border: '1px solid black',
                    my: '4px',
                    p: '4px',
                    backgroundColor: 'darkgrey',
                    height: '80px'
                }} />
            </Box>
        );
    }

    const author = forum.users[post.userId];

    if (user === undefined || author === undefined || user.id !== author.id) {
        throw new Error('Unauthorized');
    }

    const savePost = (post: SavePostModel) => {
        Axios
            .post(`/api/posts/${postId}/edit`, { ...post, parentId: postId })
            .then((_: AxiosResponse<string>) => {
                navigate(`/posts/${postId}`);
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }

    const removePost = () => {
        Axios
            .post(`/api/posts/${postId}/remove`)
            .then((_: AxiosResponse<string>) => {
                navigate('/f');
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }

    const hasBody = post.body !== undefined && post.body !== null && post.body.length > 0;
    const sanitizedBody = hasBody && DOMPurify.sanitize(post.body, { USE_PROFILES: { html: true } });

    return (
        <Box
            sx={{
                mx: '1em',
                paddingTop: '1em'
            }}>
            <Box
                sx={{
                    border: '1px solid black',
                    my: '4px',
                    p: '4px',
                    backgroundColor: 'darkgrey'
                }}>
                <strong>{post.subject}</strong>
                <div style={{ fontSize: 'small' }}>
                    {author !== undefined &&
                        <span style={{ whiteSpace: 'break-spaces' }}>posted by <strong>{author.name}</strong></span>
                    }
                    <span style={{ whiteSpace: 'break-spaces' }}>&nbsp;<small><Moment date={post.createdAt} fromNow /></small></span>
                    <span>
                        {
                            post.hasImages &&
                            <Icons.ImageOutlined color='success' fontSize='small' sx={{ verticalAlign: 'middle' }} />
                        }
                        {
                            post.hasUrl &&
                            <Icons.Link color='warning' fontSize='small' sx={{ verticalAlign: 'middle' }} />
                        }
                    </span>
                </div>
                <div style={{ margin: '10px' }}>
                    {hasBody ?
                        <Box
                            sx={{
                                '& > img': {
                                    maxWidth: '100%'
                                }
                            }}
                            dangerouslySetInnerHTML={{ __html: post.body }} />
                        :
                        <Chip size='small' label='NT' color='info' />
                    }
                </div>
                <div>
                    <Button variant="contained" size='small' color="error" onClick={() => removePost()}>Remove Post</Button>
                </div>
            </Box>
            <NewPostForm editPost={post} savePost={savePost} />
        </Box>
    );
};

export default EditPost;