import React from "react";
import { User } from "./Models";

export type ForumAction = {
    type: 'setUsers',
    users: _.Dictionary<User>;
};

export const ForumReducer = (state: ForumReducerModel, action: ForumAction): ForumReducerModel => {
    if (action.type === 'setUsers') {
        return { ...state, users: action.users };
    }

    return state;
};

export const ForumContext = React.createContext<[state: ForumReducerModel, userDispatch: React.Dispatch<ForumAction>]>([new Object as ForumReducerModel, new Object as React.Dispatch<ForumAction>]);


type ForumReducerModel = {
    readonly users: _.Dictionary<User>;
};


//TODO: Probably dont need this?