import React from 'react';
import { Box, Button, FormGroup, TextField } from '@mui/material';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { PostModel, SearchModel } from '../../Models';
import PostSummary from './PostSummary';

const Search = () => {
    const [query, setQuery] = React.useState('');
    const [results, setResults] = React.useState<PostModel[] | undefined>(undefined);

    const search = (_: React.MouseEvent<HTMLButtonElement> | undefined) => {
        Axios
            .post<PostModel[], AxiosResponse<PostModel[]>, SearchModel>('api/posts/search', {
                query: query,
                userIds: []
            })
            .then((response: AxiosResponse<PostModel[]>) => {
                setResults(response.data);
            })
            .catch((response: AxiosError) => {
                console.debug(response);
            });
    };

    return (
        <Box mx='1em' paddingTop='1em'>
            <Box>
                <h3>Search</h3>
                <FormGroup sx={{ maxWidth: '300px' }}>
                    <TextField label="Query" onChange={event => setQuery(event.target.value)} variant="outlined" />
                    <br />
                    <Button variant='outlined' onClick={search}>Search</Button>
                </FormGroup>
                {results !== undefined &&
                    <Box>
                        <h5>Results</h5>
                        {results.length === 0 ?
                            <h5>Nope</h5>
                            :
                            results.map(result => {
                                return (
                                    <div style={{ border: '1px solid black' }}>
                                        <PostSummary post={{ ...result, childPosts: [] }} level={0} />
                                    </div>
                                )
                            })}
                    </Box>
                }
            </Box>
        </Box>
    );
};

export default Search;