import React from 'react';
import { Box } from '@mui/material';
import NewThread from './NewThread';
import * as SignalR from '@microsoft/signalr'
import Axios, { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { PostModel } from '../../Models';
import Threads from './Threads';
import { useParams } from 'react-router';

// let connection = new SignalR.HubConnectionBuilder()
//     .withUrl("/forumhub")
//     .configureLogging(SignalR.LogLevel.Information)
//     .withAutomaticReconnect()
//     .build();

const Forum = () => {
    const { pageNumber } = useParams();
    const [threads, setThreads] = React.useState<PostModel[]>([]);

    React.useEffect(() => {
        Axios
            .get(`/api/posts/threads/${pageNumber ?? ''}`)
            .then((response: AxiosResponse<PostModel[]>) => {
                setThreads(response.data);
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }, [pageNumber]);

    console.debug(threads);

    return (
        <Box mx='1em' paddingTop='1em'>
            <Threads threads={threads} />
            <NewThread />
        </Box>
    );
};

export default Forum;