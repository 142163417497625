import Axios, { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import NewPostForm from './PostForm';
import { SavePostModel } from '../../Models';

const NewThread = () => {
    const navigate = useNavigate();

    const savePost = (post: SavePostModel) => {
        Axios
            .post('/api/posts/new', post)
            .then((response: AxiosResponse<string>) => {
                navigate(`/posts/${response.data}`)
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    };

    return (
        <NewPostForm savePost={savePost} />
    );
}

export default NewThread;
