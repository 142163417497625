import React from 'react';
import { Box, Button, Chip } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { SavePostModel, PostModel, Subscription } from '../../Models';
import Moment from 'react-moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import DOMPurify from 'dompurify';
import { ForumContext } from '../../ForumReducer';
import PostSummary from './PostSummary';
import { SubscriptionsContext } from '../../SubscriptionsReducer';
import NewPostForm from './PostForm';
import { UserContext } from '../../UserReducer';
import _ from 'lodash';

const Post = () => {
    const navigate = useNavigate();
    const { postId } = useParams();
    const [user, _userReducer] = React.useContext(UserContext);
    const [forum, _forumReducer] = React.useContext(ForumContext);
    const [subscriptions, subscriptionsDispatch] = React.useContext(SubscriptionsContext);
    const [post, setPost] = React.useState<PostModel | undefined>(undefined);

    React.useEffect(() => {
        Axios
            .get(`/api/posts/${postId}`)
            .then((response: AxiosResponse<PostModel>) => {
                setPost(response.data);
                subscriptionsDispatch({
                    type: 'updateSubscription',
                    threadId: response.data.topLevelThread.id
                })
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }, [postId]);

    if (!post || post.isRemoved) {
        return (
            <Box
                sx={{
                    mx: '1em',
                    paddingTop: '1em'
                }}>
                <Box sx={{
                    border: '1px solid black',
                    my: '4px',
                    p: '4px',
                    backgroundColor: 'darkgrey',
                    height: '80px'
                }} />
            </Box>
        );
    }

    const savePost = (post: SavePostModel) => {
        Axios
            .post('/api/posts/new', { ...post, parentId: postId })
            .then((response: AxiosResponse<string>) => {
                navigate(`/posts/${response.data}`)
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }

    //TODO: used shared functions in a Context
    const subscribe = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        Axios
            .post(`/api/subscriptions/${post.topLevelThread.id}/subscribe`)
            .then(({ data }: AxiosResponse<Subscription>) => {
                subscriptionsDispatch({
                    type: 'addSubscription',
                    subscription: data
                })
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }
    const unsubscribe = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        Axios
            .post(`/api/subscriptions/${post.topLevelThread.id}/unsubscribe`)
            .then((_: AxiosResponse) => {
                subscriptionsDispatch({
                    type: 'removeSubscription',
                    threadId: post.topLevelThread.id
                })
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }

    const isSubscribed = _(subscriptions).some(sub => sub.threadId === post.topLevelThread.id);

    const author = forum.users[post.userId];
    const userIsAuthor = post.userId === user?.id;

    const hasBody = post.body !== undefined && post.body !== null && post.body.length > 0;
    const sanitizedBody = hasBody && DOMPurify.sanitize(post.body, { USE_PROFILES: { html: true } });

    return (
        <Box
            sx={{
                mx: '1em',
                paddingTop: '1em'
            }}>
            <Box
                sx={{
                    border: '1px solid black',
                    my: '4px',
                    p: '4px',
                    backgroundColor: 'darkgrey'
                }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div>
                        <strong>{post.subject}</strong>
                        <div style={{ fontSize: 'small' }}>
                            {author !== undefined &&
                                <span style={{ whiteSpace: 'break-spaces' }}>posted by <strong>{author.name}</strong></span>
                            }
                            <span style={{ whiteSpace: 'break-spaces' }}>&nbsp;<small><Moment date={post.createdAt} fromNow /></small></span>
                            <span>
                                {
                                    post.hasImages &&
                                    <Icons.ImageOutlined color='success' fontSize='small' sx={{ verticalAlign: 'middle' }} />
                                }
                                {
                                    post.hasUrl &&
                                    <Icons.Link color='warning' fontSize='small' sx={{ verticalAlign: 'middle' }} />
                                }
                            </span>
                        </div>
                    </div>
                    {userIsAuthor &&
                        <div><Button size='small' color='warning' variant='contained' component={Link} to={`/posts/${postId}/edit`}>Edit</Button></div>
                    }
                </Box>
                <div style={{ margin: '10px' }}>
                    {hasBody ?
                        <Box
                            sx={{
                                '& > img': {
                                    maxWidth: '100%'
                                }
                            }}
                            dangerouslySetInnerHTML={{ __html: post.body }} />
                        :
                        <Chip size='small' label='NT' color='info' />
                    }
                </div>
            </Box>
            <Box
                sx={{
                    border: '1px solid black',
                    my: '4px',
                    p: '4px',
                    backgroundColor: 'darkgrey',
                    fontSize: 'small'
                }}>
                <div style={{ fontSize: 'small' }}><strong>Thread</strong></div>
                <PostSummary
                    post={post.topLevelThread}
                    level={0}
                    selectedPostId={post.id}
                    isSubscribed={isSubscribed}
                    subscribe={subscribe}
                    unsubscribe={unsubscribe}
                />
            </Box>

            <NewPostForm savePost={savePost} />
        </Box>
    );
};

export default Post;