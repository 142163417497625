import { Box } from '@mui/material';
import * as Icons from '@mui/icons-material';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { PostModel } from '../../Models';
import ThreadSummary from './ThreadSummary';

const numberOfThreadsPerPage = 20;

type Props = {
    readonly threads: readonly PostModel[];
};

const Threads = (props: Props) => {
    const [numberOfPages, setNumberOfPages] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        Axios
            .get('/api/posts/threads/count')
            .then((response: AxiosResponse<number>) => {
                setNumberOfPages(Math.ceil(response.data / numberOfThreadsPerPage));
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }, [])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px', fontSize: '11px' }}>
            {props.threads.map(thread => {
                return (
                    <ThreadSummary
                        thread={thread} />
                );
            })}
            {numberOfPages &&
                <div style={{ textAlign: 'center' }}>
                    <PagesNumbers numberOfPages={numberOfPages} />
                </div>
            }
        </Box>
    );
};

export default Threads;

const PagesNumbers = (props: { numberOfPages: number }) => {
    const { pageNumber } = useParams();
    const currentPageNumber = pageNumber ? parseInt(pageNumber) : 1;

    const pages = currentPageNumber !== undefined && _.range(currentPageNumber - 3, currentPageNumber + 3).filter(page => page > 1 && page < props.numberOfPages);

    return (
        <div style={{ display: 'flex', columnGap: '5px' }}>
            <Link to={'/f/1'} style={{ border: '1px solid black', padding: '2px 6px', backgroundColor: 'lightpink', textDecoration: 'none', fontWeight: 'bold' }}>1</Link>
            {pages && !pages.includes(2) &&
                <div>...</div>
            }
            {pages && pages.map(page => {
                return (
                    <Link to={`/f/${page}`} style={{ border: '1px solid black', padding: '2px 6px', backgroundColor: 'lightpink', textDecoration: 'none', fontWeight: 'bold' }}>{page}</Link>
                );
            })}
            {pages && !pages.includes(props.numberOfPages - 1) &&
                <div>...</div>
            }
            <Link to={`/f/${props.numberOfPages}`} style={{ border: '1px solid black', padding: '2px 6px', backgroundColor: 'lightpink', textDecoration: 'none', fontWeight: 'bold' }}>{props.numberOfPages}</Link>
        </div>
    );
};