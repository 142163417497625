import React from 'react';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { Box, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserReducer';
import { UpdateUser, User } from '../Models';


const ManageUser = () => {
    const [user, userDispatch] = React.useContext(UserContext);
    const navigate = useNavigate();
    const [userForm, setUserForm] = React.useState<UpdateUser>({
        name: user?.name ?? '',
        email: user?.email ?? '',
        password: '',
        role: user?.role
    });

    React.useEffect(() => {
        if (user) {
            setUserForm({
                name: user.name,
                email: user.email,
                password: '',
                role: user.role
            });
        }
    }, [user])

    if (!user) {
        return <h4>User Not Found? 🤷‍♀️</h4>
    }

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserForm({ ...userForm, [name]: event.target.value });
    };

    const editUser = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        Axios
            .post<User, AxiosResponse<User>, UpdateUser>(`api/users/${user.id}/edit`, userForm)
            .then((response: AxiosResponse<User>) => {
                userDispatch({
                    type: 'setUser',
                    user: response.data
                });
                navigate('/f');
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    };

    const logout = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        Axios
            .post('/api/logout')
            .then((response: AxiosResponse) => {
                userDispatch({
                    type: 'setUser',
                    user: undefined
                });
                navigate('/login');
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    };

    const deleteallPosts = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        Axios
            .post('/api/posts/deleteall')
            .then((response: AxiosResponse) => {
                navigate('/f');
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    };

    const roleOptions = ['Member', 'Admin', 'Banned'];

    return (
        <Container>
            <h3>User</h3>
            <Box display='grid' flexDirection='row' rowGap='1em'>
                <h5>{user.id}</h5>
                <input type='text' placeholder='name' value={userForm.name} onChange={handleChange('name')} />
                <input type='text' placeholder='email' value={userForm.email} onChange={handleChange('email')} />
                <input type='text' placeholder='password' value={userForm.password} onChange={handleChange('password')} />
                
                {user.role === 'Admin' &&
                    <select value={userForm.role} onChange={(event) => setUserForm({ ...userForm, role: event.target.value })}>
                        {roleOptions.map(role => <option value={role}>{role}</option>)}
                    </select>
                }

                <Button variant='contained' color='primary' onClick={editUser}>Save User</Button>
                <Button variant='contained' color='warning' onClick={logout}>Logout</Button>

                {user.name === 'Evan' &&
                    <Button variant='contained' color='error' onClick={deleteallPosts}>deleteallPosts</Button>
                }
            </Box>

        </Container>
    );
}

export default ManageUser;
