import React from 'react';
import { Box } from '@mui/material';
import PostSummary from './PostSummary';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { PostModel, Subscription } from '../../Models';
import { SubscriptionsContext } from '../../SubscriptionsReducer';

type Props = {
    readonly thread: PostModel;
};

const ThreadSummary = (props: Props) => {
    const [subscriptions, subscriptionsDispatch] = React.useContext(SubscriptionsContext);

    const subscribe = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        Axios
            .post(`/api/subscriptions/${props.thread.id}/subscribe`)
            .then(({ data }: AxiosResponse<Subscription>) => {
                subscriptionsDispatch({
                    type: 'addSubscription',
                    subscription: data
                })
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }

    const unsubscribe = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        Axios
            .post(`/api/subscriptions/${props.thread.id}/unsubscribe`)
            .then(({ data }: AxiosResponse<Subscription>) => {
                subscriptionsDispatch({
                    type: 'removeSubscription',
                    threadId: props.thread.id
                })
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }

    const subscription = subscriptions ? subscriptions.find(subscribedThread => subscribedThread.threadId == props.thread.id) : undefined;
    const isSubscribed = subscription !== undefined;
    const isUnread = isSubscribed && subscription.lastReadAt < props.thread.lastReplyInThreadAt;

    return (
        <Box
            display='flex'
            flexDirection='column'
            justifyContent='left'
            sx={{
                backgroundColor: isUnread ? 'lightblue' : 'darkgray',
                p: '2px',
                border: '1px solid black'
            }}>
            <PostSummary
                post={props.thread}
                level={0}
                isSubscribed={isSubscribed}
                subscribe={subscribe}
                unsubscribe={unsubscribe} />
        </Box>
    );
};

export default ThreadSummary;