import React from 'react';
import reportWebVitals from '../reportWebVitals';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Subscription, User } from '../Models';
import { UserContext, UserReducer } from '../UserReducer';
import { SubscriptionsContext, SubscriptionsReducer } from '../SubscriptionsReducer';
import { ForumContext, ForumReducer } from '../ForumReducer';

const App = () => {
    //reportWebVitals(console.log);
    const [forum, forumDispatch] = React.useReducer(ForumReducer, { users: {} });
    const [user, userDispatch] = React.useReducer(UserReducer, undefined);
    const [subscriptions, subscriptionsDispatch] = React.useReducer(SubscriptionsReducer, []);

    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        getUser();
        listUsers();
    }, []);

    const getUser = () => {
        Axios
            .get('/api/users/me')
            .then((response: AxiosResponse<User>) => {
                userDispatch({
                    type: 'setUser',
                    user: response.data
                });

                getSubscriptions();

                if (location.pathname === '/') {
                    navigate('/f');
                }
            })
            .catch((error: AxiosError) => {
                console.debug(error);
                if (error.response?.status === 401) {
                    navigate("/login");
                }
            });
    };

    const getSubscriptions = () => {
        Axios
            .get('/api/subscriptions')
            .then((response: AxiosResponse<Subscription[]>) => {
                subscriptionsDispatch({
                    type: 'setSubscriptions',
                    subscriptions: response.data
                });

                if (location.pathname === '/') {
                    navigate('/f');
                }
            })
            .catch((error: AxiosError) => {
                console.debug(error);
                if (error.response?.status === 401) {
                    navigate("/login");
                }
            });
    };

    const listUsers = () => {
        Axios
            .get('/api/users')
            .then((response: AxiosResponse<_.Dictionary<User>>) => {
                forumDispatch({
                    type: 'setUsers',
                    users: response.data
                });
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    };

    return (
        <ForumContext.Provider value={[forum, forumDispatch]}>
            <UserContext.Provider value={[user, userDispatch]}>
                <SubscriptionsContext.Provider value={[subscriptions, subscriptionsDispatch]}>
                    <div>
                        <Box>
                            <AppBar position="static">
                                <Toolbar sx={{ justifyContent: "space-between", backgroundColor: 'gray' }}>
                                    <Link to="/f" style={{ cursor: 'pointer' }}><img src='/favicon.ico' style={{ height: '40px' }} /></Link>
                                    {user &&
                                        <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px', fontSize: 'small' }}>
                                            <Link to="/f">forum</Link>
                                            <Link to="/search">search</Link>
                                            <Link to="/media">media</Link>
                                            <Link to="/messages">messages</Link>
                                        </div>
                                    }
                                    {user ?
                                        <Button sx={{ color: 'black' }} variant='contained' component={Link} to="/user">{user.name}</Button>
                                        :
                                        <Button sx={{ color: 'black' }} variant='contained' component={Link} to="/login">Login</Button>
                                    }

                                </Toolbar>
                            </AppBar>
                        </Box>
                        <Box>
                            <Outlet />
                        </Box>
                    </div>
                </SubscriptionsContext.Provider>
            </UserContext.Provider>
        </ForumContext.Provider>
    );
}

export default App;
