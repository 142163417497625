import React from "react";
import { User } from "./Models";

export type UserAction = {
    type: 'setUser',
    user: User | undefined
};

export const UserReducer = (state: User | undefined, action: UserAction): User | undefined => {
    if (action.type === 'setUser') {
        return action.user;
    }

    return state;
};

export const UserContext = React.createContext<[state: User | undefined, userDispatch: React.Dispatch<UserAction>]>([undefined, new Object as React.Dispatch<UserAction>]);
