import React from 'react';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { Box, Button, FormGroup, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserReducer';
import { User } from '../Models';

const NewUser = () => {
    const navigate = useNavigate();
    const [_, userDispatch] = React.useContext(UserContext);
    const [values, setValues] = React.useState({
        name: '',
        password: '',
        email: ''
    });

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [name]: event.target.value });
    };

    const createNewUser = (_: React.MouseEvent<HTMLButtonElement> | undefined) => {
        Axios
            .post('/api/newuser', {
                name: values.name,
                password: values.password,
                email: values.email
            })
            .then((response: AxiosResponse<User>) => {
                userDispatch({
                    type: 'setUser',
                    user: response.data
                });
                navigate('/f');
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    };

    return (
        <Box
            sx={{
                m: 4,
                '& .MuiTextField-root': { m: 1 }
            }}
            component="form"
            noValidate
        >
            <div>
                <FormGroup>
                    <TextField label="Name" autoComplete='username' onChange={handleChange('name')} variant='outlined' />
                    <TextField label="Password" type='password' autoComplete='new-password' onChange={handleChange('password')} variant='outlined' />
                    <TextField label="Email" type='email' autoComplete='email' onChange={handleChange('email')} variant='outlined' />
                </FormGroup>
                <Box
                    sx={{ my: 4 }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Button variant="contained" color="success" onClick={createNewUser}>Create New User</Button>
                </Box>
            </div>
        </Box>
    );
}

export default NewUser;
