import React from 'react';
import { Box, Button } from '@mui/material';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { MediaModel } from '../Models';
import _ from 'lodash';
import Moment from 'react-moment';

const Media = () => {
    const [allMedia, setAllMedia] = React.useState<MediaModel[]>([]);
    const [selectedImage, setSelectedImage] = React.useState<File | undefined>(undefined);

    React.useEffect(() => {
        Axios
            .get('/api/media/currentuser')
            .then(({ data }: AxiosResponse<MediaModel[]>) => {
                setAllMedia(data);
            })
            .catch((error: AxiosError) => {
                console.debug(error);
            });
    }, [])


    const uploadImage = (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if (event && event.target.files !== null) {
            const data = new FormData();
            data.append("media", event.target.files[0]);

            Axios
                .post('/api/media/upload', data)
                .then((_: AxiosResponse<string>) => {
                    setSelectedImage(event.target.files![0]);
                })
                .catch((error: AxiosError) => {
                    console.debug(error);
                });
        }
    }



    return (
        <Box mx='1em' paddingTop='1em'>
            <h3>Media</h3>
            <Box my='1em'>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={uploadImage}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="outlined" component="span">
                        Upload
                    </Button>
                </label>
            </Box>
            <div style={{ display: 'flex', columnGap: '1em', rowGap: '1em' }}>
                {allMedia.map(media => {
                    return (
                        <div title={media.fileName}>
                            <div>
                                <img style={{ maxWidth: '300px' }} src={media.fileUrl} />
                            </div>
                            <div>
                                <small><small>{media.fileName}</small></small>
                            </div>
                            <div>
                                <small><small><Moment date={media.uploadedAt} fromNow /></small></small>
                            </div>
                        </div>
                    )
                })}
            </div>
            {selectedImage &&
                <img
                    alt="not found"
                    width="250px"
                    src={URL.createObjectURL(selectedImage)}
                />
            }
        </Box>
    );
};

export default Media;